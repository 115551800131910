<template>
    <div class="link-view">
        <CardComponent>
            <LoadingComponent
                v-show="loading"
                stroke-color="#579ae6"
                overlay="rgba(255, 255, 255, 0.8)"
            />
            <template #header>
                <RowComponent align="center">
                    <HelpComponent
                        markdown="hosted-link"
                        external-link="https://www.surveylegend.com/user-guide/sharing-surveys/"
                    />
                    <span>Hosted link</span>
                </RowComponent>
            </template>
            <InputComponent :modelValue="shortLink" readonly @click="$refs.shortLink.onClick()">
                <template #append>
                    <CopyComponent ref="shortLink" :value="shortLink" />
                </template>
            </InputComponent>
        </CardComponent>
        <CardComponent>
            <template #header>
                <RowComponent align="center">
                    <HelpComponent
                        markdown="external-id"
                        external-link="https://www.surveylegend.com/user-guide/track-identify-using-external-ids/"
                    />
                    <span>Generate a link with an External ID</span>
                </RowComponent>
            </template>
            <InputComponent v-model.trim="externalId" placeholder="Type a custom External ID" />
            <template v-if="externalId">
                <DividerComponent />
                <InputComponent
                    :modelValue="customLink"
                    readonly
                    @click="$refs.customLink.onClick()"
                >
                    <template #append>
                        <CopyComponent ref="customLink" :value="customLink" />
                    </template>
                </InputComponent>
            </template>
        </CardComponent>
    </div>
</template>

<script>
import SurveyService from '@/services/survey-service'

export default {
    name: 'Link',

    metaInfo: {
        title: 'Link'
    },

    data() {
        return {
            loading: false,
            shortLink: '',
            externalId: ''
        }
    },

    computed: {
        longLink() {
            return `${window.location.origin}/survey/${this.$route.params.id}`
        },

        customLink() {
            return `${this.longLink}?${encodeURI(this.externalId)}`
        }
    },

    async mounted() {
        this.loading = true

        try {
            const { data } = await SurveyService.getShortLink({ id: this.$route.params.id })

            this.shortLink = data
        } catch (error) {
            this.shortLink = this.longLink
        }

        this.loading = false
    }
}
</script>

<style scoped lang="scss">
.link-view {
    .loading-component {
        border-radius: 6px;
    }
}
</style>
